import React from "react"
import _ from 'lodash';
import PropTypes from "prop-types";
import { debouncedSetTokenInStore } from "../helpers/tokenHelper"
import { connect } from "react-redux"
import { loginUser, setToken } from "../store/actions/authActions"
import App from "./App"

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: ''
    }
  }

  render() {
    const { username, password } = this.state
    const { token, children } = this.props

    if(_.isEmpty(token)) {
      return (
        <form onSubmit={this.login}>
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-6">
              <div className="form-group">
                <label>Username:</label>
                <input className="form-control" placeholder="Username..." type="text" value={username} onChange={(e) => this.setState({ username: e.target.value })} />
              </div>

              <div className="form-group">
                <label>Password:</label>
                <input className="form-control" placeholder="Password..." type="password" value={password} onChange={(e) => this.setState({ password: e.target.value })} />
              </div>

              <button className="btn btn-block btn-primary" type="submit">Login</button>
            </div>
          </div>
        </form>
      );
    }

    return (
      <App>
        {children}
      </App>
    );
  }

  login = (e) => {
    e.preventDefault();
    const { username, password } = this.state;

    this.props.loginUser(username, password);
  }

  setToken = (e) => {
    this.props.setToken(e.target.value);
    debouncedSetTokenInStore(e.target.value);
  }
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
}


const mapStateToProps = (state) => {
  const { token } = state;
  return {
    token: token
  };
};

const mapDispatchToProps = {
  setToken: setToken,
  loginUser: loginUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
