import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { setTokenInStore } from "../helpers/tokenHelper"
import { connect } from "react-redux"
import { getAllGuestGroups, getAllGuestGroupXrefs, getAllGuests } from "../store/actions/guestActions"
import { setToken } from "../store/actions/authActions"

class App extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.location !== this.props.location) {
      console.log({ p: prevProps.location, c: this.props.location });
    }
  }

  render() {
    const { token, children } = this.props

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
          <div className="container">
            <a className="navbar-brand" href="#">Scott and Micaela</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/guests">Guests</Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Groups
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/groups/status">Status</Link>
                    <Link className="dropdown-item" to="/groups/addresses">Addresses</Link>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0);" onClick={this.logout}>Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container">
          <div className="row">
            <div className="col-12">
              {children}
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.props.getAllGuests();
    this.props.getAllGuestGroups();
    this.props.getAllGuestGroupXrefs();
  }

  logout = () => {
    setTokenInStore(undefined);
    this.props.setToken(undefined);
  }
}

App.propTypes = {
  children: PropTypes.any.isRequired,
}


const mapStateToProps = ({ token }) => {
  return {
    token: token
  };
};

const mapDispatchToProps = {
  setToken: setToken,
  getAllGuests: getAllGuests,
  getAllGuestGroups: getAllGuestGroups,
  getAllGuestGroupXrefs: getAllGuestGroupXrefs,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
